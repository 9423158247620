<template>
  <div>
    <b-card class="mb-2">
       <div class="d-flex justify-content-between">
        <h1 class="my-auto">Liste des Secteurs</h1>
        <b-button
          variant="primary"
          v-b-modal.modal-center
        >
          <feather-icon
            icon="PlusIcon"
            class="mr-25"
          />
          <span>Ajouter un secteur</span>
        </b-button>
        
      </div>
    </b-card>
    <b-card > 
      <b-row>
        <table class="table">
          <thead>
            <tr>
              <th scope="col">#</th>
              <th scope="col">Noms</th>
              <th scope="col">Descriptions</th>
              <th scope="col">Actions</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(secteur, keySecteur) in tableData" v-if="tableData && tableData.length > 1">
              <th scope="row">
                <strong>{{ keySecteur + 1 }}</strong>
              </th>
              <td>{{secteur.nom}}</td>
              <td>{{secteur.description}}</td>
              <td>
                <b-button-group>
                  <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="outline-primary"
                  >
                    <feather-icon
                      icon="Edit2Icon"
                      class="mr-25"
                    />
                    Modifier
                  </b-button>
                </b-button-group>
              </td>
            </tr>
            <tr v-else>
              Aucune donnée trouvée
            </tr>
          </tbody>
        </table>
      </b-row>
      <!-- pagination -->
      <div class="d-flex justify-content-between mt-1" v-if="tableData && tableData.length > 1">
        <div class="d-flex flex-row"> 
          <span class="my-auto mr-1">Afficher </span>
          <div>
            <b-form-select
              v-model="paginationData.perPage"
              :options="optionsPerPage"
              size="sm"
              class="form-control col"
            />
          </div>
          <span class="my-auto ml-1">lignes </span>
        </div>

        <div>
          <b-pagination
            v-model="paginationData.currentPage"
            :total-rows="paginationData.total"
            :per-page="paginationData.perPage"
            first-number
            last-number
            class="mb-0 mt-1 mt-sm-0"
            prev-class="prev-item"
            next-class="next-item"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>
        </div>

        <div class="my-auto">
          <!-- <div class="d-flex flex-row">  -->
            <span class="my-auto">Affichage de {{paginationData.metaData.from}} à {{paginationData.metaData.to}} sur {{paginationData.total}} </span>
          <!-- </div> -->
        </div>
      </div>
    </b-card>



    <!-- Info modal -->
    <b-modal
      id="modal-center"
      centered
      title="Nouveau Secteur"
      ok-only
      ok-title="Valider"
      cancel-title="Fermer"
      cancel-variant="outline-secondary"
      size="lg"
      @ok="saveNewSecteur()"

    >
      <b-card-text >
        <div class="mb-3">{{newSecteur}}</div>
        <!-- <div class=" p-1"> -->
          <div class="row col-12">
            <label for="" class="col-3 my-auto"><strong>Nom</strong></label>
            <b-form-input     
              v-model="newSecteur.nom"       
              type="text"
              class="form-control col-9"
              placeholder="Nom du secteur"
            />
          </div>
          <div class="row col-12 mt-1">
            <label for="" class="col-3 my-auto"><strong>Description</strong></label>
            
            <b-form-textarea
              v-model="newSecteur.description"
              placeholder="Description du secteur"
              rows="3"
              class="form-control col-9"
            />
          </div>
        <!-- </div> -->
        
      </b-card-text>
    </b-modal>

  </div>
</template>
  

<script>
import {
  BTable, BCard, BBadge, BRow, BCol, BFormGroup, BFormSelect,
  BPagination, BInputGroup, BFormInput, BInputGroupAppend, 
  BButton, BSpinner, BButtonGroup, VBModal, BModal, VBTooltip,
  BCardText,BFormTextarea,
} from 'bootstrap-vue'
// import SecteurCreate from './SecteurCreate'
import { mapActions } from 'vuex'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BTable,
    BCard,
    BBadge,
    BRow,
    BCol,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BSpinner,
    BButtonGroup,
    VBModal, BModal,
    BCardText,BFormTextarea,
  },
  watch: {
    'paginationData.currentPage': {
      handler(val, old) {
        this.loadData()
      },
    },
    'paginationData.perPage': {
      handler(val, old) {
        this.loadData()
      },
    },
    $route: {
      immediate: true,
      async handler(val, old) {
        this.loadData()
      },
    },
  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      isLoadingTableData: false,
      tableData: null,
      // tableColumns: [
      //   { key: 'id', label: '#', sortable: false },
      //   { key: 'nom', label: 'Role',sortable: true, sortDirection: 'asc' },
      //   { key: 'permissions', label: 'Permissions', },
      //   { key: 'actions', label: 'Actions' }
      // ],
      paginationData: {
        currentPage: 1,
        total: 0,
        perPage: 10,
        metaData: { from: 0, to: 0 },
      },
      optionsPerPage: [10, 15, 30, 100, 200],
      newSecteur: {
        nom:'', description:'',
      },
      permissionsActions: ['create', 'read', 'update', 'delete', 'access' ],

      totalRows: 1,
      currentPage: 1,
     
      // sortDirection: 'asc',
      filter: null,
      filterOn: [],
      infoModal: {
        id: 'info-modal',
        title: '',
        content: ''
      }
    }
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => {
          return { text: f.label, value: f.key }
        })
    }
  },

  mounted() {
    // Set the initial number of items
  },
  methods: {
    ...mapActions('secteurs', {
      fetchSecteurs: 'fetchSecteurs',
      addSecteurs: 'addSecteurs',
    }),
    loadData(){
      this.isLoadingTableData = true 
      const currentParams = {
        page: this.paginationData.currentPage - 1,
        perPage: this.paginationData.perPage,
      }
      console.log('params: ', currentParams)
      this.fetchSecteurs({params: currentParams || null})
        .then(res => {
          console.log('res: ', res.data)
          this.isLoadingTableData = false
          this.tableData = res.data.secteurs
          this.paginationData.total = res.data.total

          this.paginationData.metaData.from = (this.paginationData.perPage * this.paginationData.currentPage) - (this.paginationData.perPage)
          if(this.paginationData.metaData.from == 0) this.paginationData.metaData.from += 1          
          this.paginationData.metaData.to = (this.paginationData.perPage * this.paginationData.currentPage) - this.paginationData.perPage + this.tableData.length
        })
        .catch(err => {
          this.isLoadingTableData = false
          console.log(err)
        })
    },
    saveNewSecteur(){
      console.log('this.newSecteur: ', this.newSecteur)
      this.addSecteurs(this.newSecteur)
        .then(res => {
          console.log('res: ', res.data)
          this.loadData()
        })
        .catch(err => {
          console.log(err)
        })
    },

    info(item, index, button) {
      this.infoModal.title = `Row index: ${index}`
      this.infoModal.content = JSON.stringify(item, null, 2)
      this.$root.$emit('bv::show::modal', this.infoModal.id, button)
    },
    resetInfoModal() {
      this.infoModal.title = ''
      this.infoModal.content = ''
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    }
  }
}
</script>
